<template>
  <div>
    <div style="margin-bottom: 10px">
      <img
        style="width: 15px"
        src="../../assets/images/goback.png"
        alt
        @click="toBack"
      />
      <span style="margin-right: 15px" @click="toBack"
        >&nbsp;现场管理检查 {{ areaName }}</span
      >
    </div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['待审核', '已审核']"
        :key="index"
        :class="{ activeColor: index == optionChoosevalue }"
        @click="optionChooseValueChange(index)"
        >{{ item }}</span
      >
    </div>
    <div class="toSave1" @click="toSave" v-show="!isCheck">
      <img style="width: 15px" src="../../assets/images/ok.png" alt />
      <span>完成检查</span>
    </div>
    <div class="mid_box" v-show="isCheck">
      <el-button type="primary" class="edit" @click="toOpen">
        抽取题目
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <el-button type="primary" class="edit" @click="addQuiz">
        新增提问
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>

      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel2()"
        >导出检查表</el-button
      >
      <span style="margin-top: 5px; margin-left: 8px">
        <img
          src="../../assets/images/Examine_img/del1.png"
          alt
          @click="delItem"
        />
      </span>
    </div>
    <div class="_TableBox" style="overflow: hidden; overflow-y: auto">
      <el-table
        v-if="isCheck"
        ref="multipleTable"
        :data="tableData1.slice(start, end)"
        tooltip-effect="dark"
        :height="elementHeight"
        style="width: 100%"
        :key="itemKey"
        id="multipleTable"
        highlight-current-row
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          prop="fileName"
          label="文件名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="question" label="问题"></el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          label="是否符合要求"
          width="420px"
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0)"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 4)"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
              <div
                @click="changeResult(scope.row, 3)"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
              <div
                @click="changeResult(scope.row, 2)"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="answer"
          v-if="this.isshowAns"
          label="查看答案"
          width="150px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="answer"
          v-else
          label="查看答案"
          width="150px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-else
        ref="multipleTable"
        :data="tableData2.slice(start, end)"
        tooltip-effect="dark"
        :height="elementHeight"
        style="width: 100%"
        :key="itemKey"
        highlight-current-row
        id="multipleTable"
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
        @selection-change="handleSelectionChange"
        :stripe="true"
        border
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          prop="fileName"
          label="文件名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="question" label="问题"></el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          label="是否符合要求"
          width="260px"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0)"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 4)"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
              <div
                @click="changeResult(scope.row, 3)"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
              <div
                @click="changeResult(scope.row, 2)"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190px"
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180px"
          prop="lawAsk"
          label="审核意见（双击列表）"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <input
             style="width: 150px"
              v-if="
                AreaAudit.id === scope.row.id && columnID === scope.column.id
              "
              type="text"
              @focus="compare(scope.row.lawAsk)"
              @blur="Updata(scope.row, scope.row.lawAsk)"
              v-model="scope.row.lawAsk"
            />
            <span v-else>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="查看答案" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="isCheck ? tableData1.length : tableData2.length"
        class="_Pagination"
      ></el-pagination>
    </div>

    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属区域:</span>
        <el-select
          v-model="areaNo"
          placeholder="请选择"
          disabled
          class="Company_choose"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.areaName"
            :value="item.areaNo"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <span
            @click="chooseCt"
            style="margin: 10px; color: #4274c0; cursor: pointer"
          >
            <i class="el-icon-plus"></i> 添加范畴
          </span>
        </div>
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 1%"
            >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist"
              :key="index"
            >
              <span
                style="
                  margin: 10px;
                  margin-left: 1%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
                >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
              <div class="topic1">
                <div>{{ item.auditTypeName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin-right: 10px; width: 60px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="PostData.question"
            maxlength="500"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div>添加图片</div>
      <!-- :on-success="getaddr" -->
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已添加图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案:{{ answer }}</div>
      <div style="font-size: 18px">图片:</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加范畴"
      :visible.sync="dialogVisibleC"
      width="30%"
    >
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleC = false">取 消</el-button>
        <el-button type="primary" @click="gettitle">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px">
        <el-form-item label="审核结果:">
          <span v-if="AuditStatus == 0">不符合</span>
          <span v-if="AuditStatus == 1">符合</span>
          <span v-if="AuditStatus == 3">改进</span>
          <span v-if="AuditStatus == 2">纠正</span>
          <span v-if="AuditStatus == 4">不适用</span>
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="Adoption" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="添加照片:">
          <!-- :action="this.BUrl + '/api/UpLoadFiles/UploadImg'" -->
          <!-- :on-success="getaddr" -->
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已添加图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px; margin-right: 3px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            ></el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      :data="tableData"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
      id="pdfDom"
    >
      <el-table-column type="index" label="序号" width="55"></el-table-column>
      <el-table-column
        prop="fileName"
        label="文件名称"
      ></el-table-column>
      <el-table-column
        prop="question"
        label="问题"
      ></el-table-column>
      <el-table-column
        prop="auditResult"
        align="center"
        label="是否符合要求"
        width="260px"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div :class="{ auditactive: scope.row.auditResult === 1 }">是</div>
            <div :class="{ auditactive: scope.row.auditResult === 0 }">否</div>
            <div :class="{ auditactive: scope.row.auditResult === 2 }">
              纠正
            </div>
            <div :class="{ auditactive: scope.row.auditResult === 3 }">
              改进
            </div>
            <div :class="{ auditactive: scope.row.auditResult === 4 }">
              不适用
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        v-if="!this.isshowAns"
        align="center"
        width="160px"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        label="答案"
        v-if="this.isshowAns"
      ></el-table-column>
      <el-table-column
        prop="answer"
        label="答案"
        v-else
      ></el-table-column>
      <el-table-column prop="lawAsk" label="审核意见（双击列表）">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from "../../api/http";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";

export default {
  data() {
    return {
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      htmlTitle: "现场检查表",
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ""],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleimg: false,
      dialogVisiblebig: false,
      dialogVisibleAsk: false,
      dialogVisibleC: false,
      dialogVisibleresult: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      Cid: null,
      itemKey: "",
      textarea: "",
      titlelist: "",
      Ctdata: [],
      imgList: [],
      srcList: [],
      isCheck: true,
      value: "",
      isshowAns: false,
      areaNo: this.$route.query.auditAreaNo,
      company: Number(this.$route.query.upCompanyId),
      number: "",
      url: "",
      imgSrc: "",
      showImg: false,
      options: "",
      optionChoosevalue: 0,
      Curindex: "",
      AreaAudit: {},
      columnID: "",
      option2: "",
      curlawask: "",
      isSave: true,
      nodeKey: [],
      start: 0,
      end: 10,
      Adoption: "",
      currentPage1: 0,
      curSize: 10,
      answer: "",
      nodeID: [],
      listID: [],
      curRow: "",
      curNum: "",
      defaultProps: {
        label: "auditTypeName",
        children: "auditTypelist",
      },
      PostData: {
        auditId: this.$route.query.Id,
        questionId: 0,
        question: "",
        answer: "",
        lawAsk: "",
        auditResult: 0,
        imagesCount: 0,
      },
      AuditStatus: 0,
      areaName: "",
    };
  },
  activated() {
    if (
      sessionStorage.getItem("SId") != this.$route.query.Id ||
      this.tableData.length == 0 //考虑刷新后
    ) {
      this.getTdata();
      this.getCompany();
      this.getElementHeight();
      console.log(JSON.parse(sessionStorage.getItem("item")));
      this.areaName = JSON.parse(sessionStorage.getItem("item")).auditAreaName;
    }
      this.isCheck = true;
      this.optionChoosevalue = 0;
  },
  mounted() {
    this.getElementHeight();
  },
  watch: {
    tableData(n, o) {
      console.log(n, o);
    },
  },
  methods: {
    getCompany() {
      this.options = this.$store.state.companyList;
      get("/api/Area/GetList").then((res) => {
        this.option2 = res.data;
      });
    },
    getTdata() {
      get(
        "/api/AreaAuditList/GetByAuditId?AuditId=" + this.$route.query.Id
      ).then((res) => {
        this.tableData = res.data;
        if (Number(this.$route.query.extractCount) - res.data.length > 0) {
          this.$message({
            type: "success",
            message:
              "重复题数：" +
              (Number(this.$route.query.extractCount) - res.data.length),
          });
        }
        this.tableData1 = this.tableData.filter((item) => {
          return item.auditResult === null;
        });
        this.tableData2 = this.tableData.filter((item) => {
          return item.auditResult !== null;
        });
      });
    },
    toSave() {
      this.isSave = true;
      this.tableData.forEach((e) => {
        console.log(e.auditResult);
        if (e.auditResult === null) this.isSave = false;
      });
      if (this.isSave) {
        var item = JSON.parse(sessionStorage.getItem("item"));
        if (item.status === 2) {
          this.$message({
            type: "success",
            message: "已经审核完成，请勿重复完成",
          });
          return;
        }
        put("/api/AreaAudit", item).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.isSave = true;
          item.status = 2;
          sessionStorage.setItem("item", JSON.stringify(item));
        });
      } else {
        this.$message({
          type: "success",
          message: "未审核完成",
        });
      }
    },
    optionChooseValueChange(e) {
      this.optionChoosevalue = e;
      this.isCheck = e == 0 ? true : false;
      this.AreaAudit = {};
    },
    toBack() {
      this.$router.push("/site-page?auditAreaNo=" + this.$route.query.auditAreaNo);
      sessionStorage.setItem("SId", this.$route.query.Id);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 新增提问
    addQuiz(){
      this.dialogVisible1 = true;
      this.PostData.question = "";
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++;
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--;
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = "";
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount;
    },
    overimg(index) {
      this.Curindex = index;
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 15 + 64 + 40 + 70); //70是div底部距离底部的高度
      });
    },
    openImg(e) {
      this.Cid = e.id;
      this.dialogVisibleimg = true;
      this.getimg();
    },
    toChange() {
      this.isCheck = !this.isCheck;
      this.start = 0;
      this.end = 10;
      this.currentPage1 = 1;
    },
    toOpen() {
      for (let index = 0; index < this.tableData.length; index++) {
        if (this.tableData[index].auditResult !== null) {
          this.$message({
            message: '已有题目审核过，不可重新抽题',
            type: 'warning'
          });
          return;
        }
      }
      this.dialogVisible = true;
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          console.log(e.id);
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/AreaAuditListImage?Id=" + e.id).then(() => {
                this.getimg();
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    iScheck(e, v) {
      this.nodeID = v.checkedNodes;
      this.nodeKey = v.checkedKeys;
    },
    chooseCt() {
      this.dialogVisibleC = true;
      this.nodeID = [];
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          get("/api/AreaAudit/GetAuditTypeList?AreaId=" + i.id).then((res) => {
            this.Ctdata = res.data;
          });
        }
      });
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.nodeKey);
      })
    },
    changeResult(row, num) {
      if (JSON.parse(sessionStorage.getItem("item")).status == 2) {
        this.$message({
          type: "warning",
          message: "已完成检查，不可重复检查",
        });
        return;
      }
      // this.$refs.upload.clearFiles(); //this.$refs.upload.clearFiles();
      this.AuditStatus = num;
      if (num >= 0) {
        this.dialogVisibleresult = true;
        this.Cid = row.id;
        this.curRow = row;
        this.curNum = num;
        this.getImage();
        return;
      }
      row.auditResult = num;
      put("/api/AreaAuditList", row).then((res) => {
        if (res.code != "200") {
          this.$message({
            type: "error",
            message: res.message,
          });
          row.auditResult = null;
          return;
        }
        this.$message({
          type: "success",
          message: res.code == 200 ? "保存成功" : res.message,
        });
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === row.id) {
            this.tableData1.splice(i, 1);
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === row.id) {
            return;
          }
        }
        this.tableData2.push(row);
        this.getTdata();
      });
    },
    toSure() {
      this.curRow.auditResult = this.curNum;
      this.curRow.lawAsk = this.Adoption;
      put("/api/AreaAuditList", this.curRow).then((res) => {
        this.$message({
          type: "success",
          message: res.code == 200 ? "保存成功" : res.message,
        });
        if (res.code == 200) {
          this.curRow.auditResult = this.curNum;
          for (var i = 0; i < this.tableData1.length; i++) {
            if (this.tableData1[i].id === this.curRow.id) {
              this.tableData1.splice(i, 1);
            }
          }
          for (var k = 0; k < this.tableData2.length; k++) {
            if (this.tableData2[k].id === this.curRow.id) {
              return;
            }
          }
          this.tableData2.push(this.curRow);
          this.getTdata();
        } else {
          this.curRow.auditResult = null;
        }
      });
      this.$refs.upload.clearFiles();
      this.dialogVisibleresult = false;
      this.srcList = [];
      this.Adoption = "";
    },
    gettitle() {
      this.nodeID = this.nodeID.reverse();
      for (let index = 0; index < this.nodeID.length; index++) {
        if (this.nodeKey.includes(this.nodeID[index].upId)) {
          this.nodeKey = this.nodeKey.filter((item) => {
            return item !== this.nodeID[index].id;
          });
        }
      }
      this.listID = [];
      for (let index = 0; index < this.nodeKey.length; index++) {
        var item = {
          AuditTypeId: this.nodeKey[index],
        };
        this.listID.push(item);
      }
      //this.nodeID = this.nodeID.reverse();
      console.log(this.listID);
      var id;
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          id = i.id;
        }
      });
      post("/api/AreaAudit/GetTitleList?AreaId=" + id, {
        data: JSON.stringify(this.listID),
      }).then((res) => {
        console.log(res, this.listID);
        this.dialogVisibleC = false;
        this.titlelist = res.data;
        this.nodeID = [];
      });
    },
    compare(e) {
      this.curlawask = e;
      //console.log(this.curlawask)
    },
    Updata(row, e) {
      this.columnID = "";
      if (this.curlawask === e) return;
      put("/api/AreaAuditList", row).then((res) => {
        console.log(res);
        this.$message({
          type: "success",
          message: res.code == 200 ? "更改成功" : res.message,
        });
        //this.getTdata()
      });
    },
    toReAdd() {
      console.log(this.titlelist);
      var id;
      this.option2.forEach((i) => {
        if (i.areaNo === this.areaNo) {
          id = i.id;
        }
      }),
        post(
          "/api/AreaAudit/AgainExtract?AuditId=" +
            this.$route.query.Id +
            "&AreaId=" +
            id,
          {
            data: JSON.stringify(this.titlelist),
          }
        ).then((res) => {
          console.log(res);
          this.getTdata();
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: res.code == 200 ? "更改成功" : res.message,
          });
        });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleClose() {},
    handlePictureCardPreview() {
      this.dialogVisiblebig = true;
    },
    exportExcel2() {
      this.start = 0;
      this.isshowAns = true;
      this.end = this.tableData.length;
      this.tableData1 = this.tableData;
      this.tableData2 = this.tableData;
      this.$nextTick(() => {
        var wb = XLSX.utils.table_to_book(document.querySelector("#pdfDom"));
        var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "现场检查.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.start = 0;
        this.end = 10;
        this.isshowAns = false;
        this.getTdata();
        return wbout;
      });
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile);
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            var data = {
              AuditListId: this.Cid,
              ImageRemark: "",
              ImageAddress: this.BUrl + "/uploads/" + res.value,
              saveUserNo: this.$store.state.userInfo.userNo,
              saveUserName: this.$store.state.userInfo.userName,
            };
            post("/api/AreaAuditListImage", {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles();
              this.getImage();
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    getImage() {
      get("/api/AreaAuditListImage/" + this.Cid).then((res) => {
        this.srcList = [];
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    // getaddr(res) {
    //   var data = {
    //     AuditListId: this.Cid,
    //     ImageRemark: "",
    //     ImageAddress: this.BUrl + "/uploads/" + res.value,
    //     saveUserNo: this.$store.state.userInfo.userNo,
    //     saveUserName: this.$store.state.userInfo.userName,
    //   };
    //   post("/api/AreaAuditListImage", { data: JSON.stringify([data]) }).then(
    //     () => {
    //       get("/api/AreaAuditListImage/" + this.Cid).then((res) => {
    //         this.srcList = [];
    //         this.imgList = res.value;
    //         res.value.forEach((element) => {
    //           this.srcList.push(element.picNameAndAddr);
    //         });
    //       });
    //     }
    //   );
    // },
    getimg() {
      this.srcList = [];
      get("/api/AreaAuditListImage/" + this.Cid).then((res) => {
        console.log(res);
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
        console.log(this.srcList);
      });
    },
    toAddASK() {
      this.dialogVisible1 = false;
      post("/api/AreaAuditList", this.PostData).then((res) => {
        if(res.code==200){
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.$route.query.extractCount = 0;
          this.getTdata();
        }else{
          this.$message.error(res.message);
        }
      });
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.dialogVisibleAsk = false;
      this.getTdata();
      this.$refs.upload.clearFiles();
    },
    handleCell(row, column) {
      this.AreaAudit = row;
      this.columnID = column.id;
    },
    togetArea(e) {
      get("/api/Area/GetByCompId?CompId=" + e).then((res) => {
        this.option2 = res.data;
      });
    },
    delItem() {
      if (JSON.stringify(this.AreaAudit) == "{}") {
        this.$message({
          type: "info",
          message: "未选择题目",
        });
        return;
      }
      // if (this.AreaAudit.questionId != 0) {
      //   this.$message({
      //     type: 'info',
      //     message: '非自定义题目不可删除',
      //   })
      //   return
      // }
      this.$confirm("此操作将永久删除该题目", "是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/AreaAuditList?Id=" + this.AreaAudit.id).then(
            (res) => {
              this.getTdata();
              this.$message({
                type: "success",
                message: res.message,
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value;
      if (value.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(value.pop());
      }
    },

    selectChange(selection, v) {
      this.AreaAudit = v;
      console.log(selection, v);
      this.$refs.multipleTable.setCurrentRow(v);
    },
    handleRowClick(row) {
      this.AreaAudit = row;
      this.$refs.multipleTable.toggleRowSelection(row);
      //   this.selectChange(this.handleSelectionList);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    isChoose(e) {
      this.srcList = [];
      this.answer = e.answer;
      this.dialogVisibleAsk = true;
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(",");
        console.log(imgdata);
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + "/uploads/" + e);
        });
      }
    },
  },
};
</script>

<style scoped>
el-upload {
  transform: rotate(0.5);
}
._Tab {
  margin-bottom: 3px;
  padding-top: 5px;
  color: #a09f9f;
}
.activeColor {
  color: black;
  border-bottom: 2px solid #4985ff;
}
@import "../../assets/style/Examine_CSS.css";
</style>
